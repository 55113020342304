import React from "react";



import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Resume from "./components/Resume/ResumeNew";
import Parent from "./components/Parent";
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
//import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
 
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Parent />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/project",
          element: <Projects />,
        },
        {
          path: "/resume",
          element: <Resume />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
    {
      path: "/static/*",
      element: <Navigate to="/" />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
